import React from 'react'
import { Link } from 'gatsby'

const Pagination = ({ path, categoryPages, isFirst, isLast, prevPage, nextPage }) => {
  return categoryPages === 1 ? (
    ''
  ) : (
    <>
      <ul className="pagination pagination-sm justify-content-center my-4">
        <li className={`mx-2 page-item ${isFirst === true ? 'disabled' : ''}`}>
          <Link className="page-link" to={`${path}${prevPage}`}>
            Previous
          </Link>
        </li>
        <li className={`mx-2 page-item ${isLast === true ? 'disabled' : ''}`}>
          <Link className="page-link" to={`${path}${nextPage}`}>
            Next
          </Link>
        </li>
      </ul>
    </>
  )
}

export default Pagination
