import React from 'react'
import { graphql } from 'gatsby'

// Components
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import PreviewPost from '../components/PreviewPost'
import Pagination from '../components/Pagination'

// Utils
import catResolve from '../../utils/catResolve'

const Category = props => {
  const { currentPage, categoryPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === categoryPages

  const prevPage = currentPage - 1 === 1 ? '/' : `/${currentPage - 1}`
  const nextPage = `/${currentPage + 1}`

  const {
    pageContext: { category },
  } = props

  const {
    data: {
      categories: { nodes: posts },
    },
  } = props

  return (
    <>
      <Layout>
        <Seo
          title={catResolve(category, true)}
          url={props.location.href}
          keywords={[`${catResolve(category, true)}`, 'blog', 'code']}
        />
        <header className="masthead">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-10 mx-auto">
                <div className="site-heading">
                  <h1>{catResolve(category, true)}</h1>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-10 mx-auto">
              {posts.map(post => {
                return <PreviewPost key={post.id} post={post} />
              })}
            </div>
          </div>
        </div>

        <Pagination
          path={`/category/${props.pageContext.category}`}
          categoryPages={categoryPages}
          isFirst={isFirst}
          isLast={isLast}
          prevPage={prevPage}
          nextPage={nextPage}
        />
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query GetCategories($category: String, $skip: Int!, $limit: Int!) {
    categories: allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      skip: $skip
      limit: $limit
      filter: { frontmatter: { category: { eq: $category } } }
    ) {
      nodes {
        id
        frontmatter {
          title
          category
          description
          date
          slug
        }
        timeToRead
      }
      totalCount
    }
  }
`

export default Category
